<template>
    <div>
        <v-list two-line height="400px" style="overflow-y: scroll" dense>
            <v-radio-group
                v-model="selectedContactIndex"
                hide-details
                class="py-0"
            >
                <template v-for="(contact, index) in phoneContacts">
                    <v-list-item
                        style="background: solid #eeeeee !important"
                        :key="index"
                        link
                        @click.stop="selectedContactIndex = index"
                    >
                        <v-list-item-action>
                            <v-radio color="primary" :value="index"></v-radio>
                        </v-list-item-action>
                        <v-list-item-content class="py-0 py-0">
                            <v-list-item-title>
                                {{ contact.name }} | {{ contact.type }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-icon small class="mr-2">mdi-phone</v-icon>
                                {{ contact.phone }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider
                        :key="`divider-${index}`"
                        v-if="index < phoneContacts.length - 1"
                    ></v-divider>
                </template>
            </v-radio-group>
        </v-list>
    </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
    name: "phone-contacts",
    components: {},
    props: {
        contacts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedContactIndex: null,
        };
    },
    computed: {
        phoneContacts() {
            return this.contacts.filter((val) => val.type && val.phone);
        },
    },
    watch: {
        selectedContactIndex() {
            if (this.selectedContactIndex) {
                this.SET_CONTACT(this.phoneContacts[this.selectedContactIndex]);
                this.$emit(
                    "update:phone",
                    this.phoneContacts[this.selectedContactIndex].phone
                );
                this.$emit(
                    "select:contact",
                    this.phoneContacts[this.selectedContactIndex]
                );
            }
        },
    },
    methods: {
        ...mapMutations("Calls", ["SET_CONTACT"]),
        // Method called by parent
        clearContact() {
            this.selectedContactIndex = null;
            this.SET_CONTACT({});
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>