var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        {
          staticStyle: { "overflow-y": "scroll" },
          attrs: { "two-line": "", height: "400px", dense: "" }
        },
        [
          _c(
            "v-radio-group",
            {
              staticClass: "py-0",
              attrs: { "hide-details": "" },
              model: {
                value: _vm.selectedContactIndex,
                callback: function($$v) {
                  _vm.selectedContactIndex = $$v
                },
                expression: "selectedContactIndex"
              }
            },
            [
              _vm._l(_vm.phoneContacts, function(contact, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: index,
                      staticStyle: { background: "solid #eeeeee !important" },
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.selectedContactIndex = index
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-radio", {
                            attrs: { color: "primary", value: index }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 py-0" },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(contact.name) +
                                " | " +
                                _vm._s(contact.type) +
                                " "
                            )
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-phone")]
                              ),
                              _vm._v(" " + _vm._s(contact.phone) + " ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  index < _vm.phoneContacts.length - 1
                    ? _c("v-divider", { key: "divider-" + index })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }